<template>
    <div class="Projectcation" style="margin-top:20px;">
        <div class="Project_button">
            <el-button type="primary" size="mini" @click="dialogVisible=true">添加教师</el-button>
        </div>
        <el-table 
            :data="tableData" 
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="100" label="#"></el-table-column>
            <el-table-column prop="name" label="教师姓名"></el-table-column>
            <el-table-column prop="age" label="年龄" width="80"></el-table-column>
            <el-table-column prop="phone" label="账号" width="120"></el-table-column>
            <el-table-column prop="email" label="邮箱地址"></el-table-column>
            <el-table-column prop="executivePosition" label="行政职务/专业技术职务" show-overflow-tooltip></el-table-column>
            <el-table-column prop="researchOrientation" label="研究方向" show-overflow-tooltip></el-table-column>
            <el-table-column prop="outcome" label="主要成果" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button @click="Modify(scope.row)" size="mini" type="primary">修改</el-button>
                    <el-button @click="deleted(scope.row)" size="mini" type="danger">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="tment_Popup">
            <el-dialog
                title="添加教师"
                :visible.sync="dialogVisible"
                width="780px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('ruleForm')"></i>
                <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" :inline='true'>
                    <el-form-item label="教师姓名" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请输入教师姓名"></el-input>
                    </el-form-item>
                    <el-form-item prop="age" label="教师年龄">
                        <el-input v-model.number="ruleForm.age" placeholder="请输入教师年龄"></el-input>
                    </el-form-item>
                    <el-form-item label="账号" prop="phone">
                        <el-input v-model="ruleForm.phone" placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" prop="password">
                        <el-input v-model="ruleForm.password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱地址" prop="email">
                        <el-input v-model="ruleForm.email" placeholder="请输入邮箱地址"></el-input>
                    </el-form-item>
                    <el-form-item prop="executivePosition" label="行政职务/专业技术职务" class="form_lable">
                        <el-input v-model="ruleForm.executivePosition" placeholder="请输入行政职务/专业技术职务"></el-input>
                    </el-form-item>
                    <el-form-item prop="researchOrientation" label="研究方向">
                        <el-input v-model="ruleForm.researchOrientation" placeholder="请输入研究方向"></el-input>
                    </el-form-item>
                    <el-form-item prop="outcome" label="主要成果">
                        <el-input type="textarea" :rows="5" v-model="ruleForm.outcome" placeholder="请输入主要成果"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="addmine('ruleForm')">确 定</el-button>
                    <el-button size="small" @click="closei('ruleForm')">取 消</el-button>
                </span>
            </el-dialog>
            <el-dialog
                title="修改教师信息"
                :visible.sync="dialogVisibleForm"
                width="780px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei('Form')"></i>
                <el-form ref="Form" :model="Form" :rules="rules" label-width="100px" :inline='true'>
                    <el-form-item label="账号" prop="phone">
                        <el-input v-model="Form.phone" placeholder="请输入账号" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="教师姓名" prop="name">
                        <el-input v-model="Form.name" placeholder="请输入教师姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱地址" prop="email">
                        <el-input v-model="Form.email" placeholder="请输入邮箱地址"></el-input>
                    </el-form-item>
                    <el-form-item prop="executivePosition" label="行政职务/专业技术职务" class="form_lable">
                        <el-input v-model="Form.executivePosition" placeholder="请输入行政职务/专业技术职务"></el-input>
                    </el-form-item>
                    <el-form-item prop="researchOrientation" label="研究方向">
                        <el-input v-model="Form.researchOrientation" placeholder="请输入研究方向"></el-input>
                    </el-form-item>
                    <el-form-item prop="outcome" label="主要成果" style="width:80%">
                        <el-input type="textarea" :rows="5" v-model="Form.outcome" placeholder="请输入主要成果"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="updatemine('Form')">确 定</el-button>
                    <el-button size="small" @click="closei('Form')">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            dialogVisible:false,
            dialogVisibleForm:false,
            ruleForm:{},
            Form:{},
            rules:{
                name:[
                    { required: true, message: '请输入教师姓名', trigger: 'blur' }
                ],
                age:[
                    { required: true, message: '请输入教师年龄', trigger: 'blur' },
                    { type: 'number', message: '年龄必须为数字值'}
                ],
                phone:[
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    {
                        pattern:  /^\d{11}$/,
                        message: "账号必须为11位数字"
                    }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                email:[
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
                executivePosition:[
                    { required: true, message: '请输入行政职务/专业技术职务', trigger: 'blur' }
                ],
                researchOrientation:[
                    { required: true, message: '请输入研究方向', trigger: 'blur' }
                ],
                outcome:[
                    { required: true, message: '请输入主要成果', trigger: 'blur' }
                ],
            },
            tableData:[],
        }
    },
    methods: {
        getapp(){
            this.axios.teacherbyaid({
                params:{
                    academyId:this.$store.state.academyId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        //添加教师
        addmine(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.ruleForm.schoolId = this.$store.state.schId
                    this.ruleForm.academyId = this.$store.state.academyId
                    this.axios.teacherradd(
                        this.ruleForm
                    ).then(res=>{
                        if(res.data.code==200){
                            this.dialogVisible =false
                            this.getapp()
                            this.$refs[formName].resetFields();
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //修改
        Modify(rows){
            console.log(rows)
            this.Form = rows
            this.dialogVisibleForm = true
        },
        updatemine(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.teacherredit(
                        this.Form
                    ).then(res=>{
                        if(res.data.code==200){
                            this.getapp()
                            this.dialogVisibleForm =false
                            this.$refs[formName].resetFields();
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    }).catch(err=>{

                    })
                }else{
                    return false
                }
            })
        },
        //删除教师
        deleted(rows){
            this.$confirm('此操作永久删除该记录，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal:false
            }).then(() => {
                this.axios.teacherdelete({
                    params:{
                        id:rows.tId
                    }
                }).then(res=>{
                    if(res.data.code==200){
                        this.getapp()
                    }
                }).catch(err=>{

                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        handleClose(done){
            console.og(done)
        },
        // 关闭弹窗
        closei(formName){
            this.dialogVisible = false
            this.dialogVisibleForm = false
            this.$refs[formName].resetFields();
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tment_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tment_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tment_Popup .el-dialog__footer{
    text-align: center;
}
.tment_Popup .el-form-item__content{
    width: 220px;
}
.tment_Popup .el-dialog__body{
    padding: 30px 50px;
}
.tment_Popup .el-form-item__content{
    width: calc(100% - 100px);
}
.Projectcation .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
.tment_Popup .el-textarea__inner{
    width: 320px;
    resize: none;
}
.tment_Popup .form_lable .el-form-item__label{
    line-height: 20px;
}
</style>